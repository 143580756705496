<template>
    <div>
        <v-row>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
                <span class="text-header-data ">
                    Correo inicio de sesion 
                </span>
                <br>
                <span>
                    {{ personalData.correo }}
                </span>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
                <span class="text-header-data">
                    Contraseña
                </span>
                <br>
                <span>
                    **************
                </span>
            </v-col>
            <!-- <v-col class="whitout-padding-top" cols="6" sm="4" md="4" lg="4" xl="4" >
                <span>
                    Última modificación: 31 may 2023
                </span>
            </v-col> -->
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;" >
                <v-btn
                    v-if="canEdit"
                    rounded
                    dark
                    class="common-botton"
                    @click="changeComponent"
                >
                <span>
                        Cambiar contraseña
                </span>
                </v-btn>
             </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
  components: {
  },
  props:{
    personalData: Object,
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    };
  },

  methods: {
    changeComponent(){
        this.$emit('changeComponent', 2);
    }
  }
};
</script>

<style scoped>

.whitout-padding-bottom{
    padding-bottom: 0px !important;
}

.whitout-padding-top{
    padding-top: 0px !important;
}


</style>