<template>
    <div>
        <commond-form 
            :request="password"
            :route="routesConfigPassword.route"
            :run="run"
            :action="routesConfigPassword.action"
            @cancel="run = false"
            @success="changeComponent"
            @afterError="changeComponent"
        >
        <template v-slot:data>
            <v-row> 
                <v-col v-if="showOldPasswordField" class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" >
                    <v-text-field
                        label="Contraseña actual"
                        outlined
                        v-model="password.oldPassword"
                        primary
                        color="#00a7e4"
                        :type="showPassword ? 'text' : 'password'"
                        append-icon="mdi-eye"
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                </v-col>
                <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" >
                    <v-text-field
                        label="Contraseña nueva"
                        outlined
                        v-model="password.newPassword"
                        color="#00a7e4"
                        required
                        :rules="requiredRule"
                        :type="showNewPassword ? 'text' : 'password'"
                        append-icon="mdi-eye"
                        @click:append="showNewPassword = !showNewPassword"
                    ></v-text-field>
                </v-col>
                <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" >
                    <v-text-field
                        label="Confirmar contraseña"
                        outlined
                        v-model="password.confirmPassword"
                        color="#00a7e4"
                        required
                        :rules="[requiredRule, confirmPasswordRule]"
                        :type="showConfirmNewPassword ? 'text' : 'password'"
                        append-icon="mdi-eye"
                        @click:append="showConfirmNewPassword = !showConfirmNewPassword"
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>

        </commond-form>

        <v-row style="padding-top: 50px;">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;" >
                <v-btn
                    rounded
                    dark
                    outlined
                    color="#00a7e4"
                    @click="changeComponent"
                    style="margin-right: 11px;"
                >
                <span>
                    Cancelar
                </span>
                </v-btn>
                <v-btn
                    rounded
                    dark
                    class="common-botton"
                    @click="afterSend"
                >
                <span>
                        Cambiar contraseña
                </span>
                </v-btn>
             </v-col>
        </v-row>
    </div>
</template>

<script>

import CommondForm from '@/components/commonComponents/CommondForm.vue';


export default {
  components: {
    CommondForm
  },
  props:{
    personalData: Object,
    routesConfigPassword: Object,
  },
  data() {
    return {
        password:{
            newPassword: "",
            confirmPassword: "",
            oldPassword: "",
        },
        run: false,
        route:'/v1/prospecto/perfil/password',
        requiredRule: [
         v => !!v || 'El campo es requerido',
        ],
        rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
        showPassword: false,
        showNewPassword: false,
        showConfirmNewPassword: false,
    };
  },

  methods: {
    changeComponent(){
        this.$emit('changeComponent', 1);
    },
    afterSend(){
        if(typeof this.routesConfigPassword.uuidCambio != 'undefined')
            this.password.uuid = this.routesConfigPassword.uuidCambio
        this.run = true
    }   
  },
  mounted(){
    if(this.rol != 'PROSPECTO'){
        this.password.idProspecto = this.personalData.idProspecto
    }
  },
  computed: {
    confirmPasswordRule() {
        return (value) => {
            if (value !== this.password.newPassword) {
                return 'Las contraseñas no coinciden';
            }
            return true;
        };
    },

    showOldPasswordField() {
        const rolesOldPasswordNotRequired = [
            "ADMIN",
            "ADMINAGENT",
            "MANAGER",
            "ADMINCC",
            "OPERACIONES",
            "MANAGERCC",
            "PROSPECTO"
        ];

        return !rolesOldPasswordNotRequired.find(e => e == this.rol);
    }
},
};
</script>

<style scoped>

.whitout-padding-bottom{
    padding-bottom: 0px !important;
}

.whitout-padding-top{
    padding-top: 0px !important;
}

.v-text-field--outlined fieldset {
    color: #00a7e4 !important;
}


</style>