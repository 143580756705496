<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Información personal
      </template>
      <v-expansion-panel-content>
        <view-data
          v-if="switch_data == 1"
          :personalData="personalData"
          :stateList="stateList"
          :routesConfig="routesConfig"
          :listings="listings"
          :canEdit="canEdit"
          @changeComponent="changeComponent"
        >
        </view-data>
        <edit-data
          v-else
          :personalData="personalData"
          :stateList="stateList"
          :routesConfig="routesConfig"
          :listings="listings"
          @changeComponent="changeComponent"
        >
        </edit-data>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewData from "./ViewData.vue";
import EditData from "./EditData.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    CommonExpansionPanel,
    ViewData,
    EditData,
  },
  props: {
    personalData: Object,
    stateList: Array,
    routesConfig: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      switch_data: 1,
      listings: {
        canalList: [],
        ramoList: [],
        campaniaList: [],
        roles: [],
        status: [],
        landings: [],
        fuenteIngresoItem: [],
        asociadoPromotoriaItems: [],
        tipoAgenteItems: [],
      },
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      roles: [],
      rol: "",
    };
  },

  watch: {
    //checar
    rol(val) {
      this.listings.roles = [];
      var permitedRoles = [];
      switch (val) {
        case "ADMIN":
          this.listings.roles = this.roles;
          break;
        case "ADMINAGENT":
          permitedRoles = ["MANAGER", "RECLUTA",val];
          break;
        case "MANAGER":
          permitedRoles = ["AGENT",val];
          break;
        case "ADMINCC":
          permitedRoles = [
            "AGENTCC",
            "AGENTCCSPONSORS",
            "MANAGERCC",
            "OPERADORCC",
            "RENOVACIONESCC",
            val
          ];
          break;
        case "MANAGERCC":
          permitedRoles = [
            "AGENTCC",
            "AGENTCCSPONSORS",
            "OPERADORCC",
            "RENOVACIONESCC",
            val

          ];
          break;
        case "OPERACIONES":
          permitedRoles = ["OPERADOR", "AGENT", val];
          break;
        case "OPERADOR":
          permitedRoles = ["AGENT", val];
          break;

        default:
          permitedRoles = [val];
          break;
      }
      permitedRoles.forEach((rol) => {
        var rolEncontrado = this.roles.find((role) => role.id === rol);
        if (rolEncontrado) this.listings.roles.push(rolEncontrado);
      });
    },
  },

  methods: {
    changeComponent(component) {
      this.switch_data = component;
      this.$emit("getData");
    },
    listas() {
      mainAxios.get(`/v1/canal/list`, this.config).then((response) => {
        this.listings.canalList = response.data;
      });
      mainAxios.get(`/v1/ramo/list`, this.config).then((response) => {
        this.listings.ramoList = response.data;
      });
      mainAxios.get(`/v1/agente/landing/list`, this.config).then((response) => {
        this.listings.landings = response.data;
      });
      mainAxios.get(`/v1/agente/roles/list`, this.config).then((response) => {
        response.data
          ? response.data.map((element) => {
              this.roles.push({
                id: element.rol ? element.rol : "No tiene",
                nombre: element.nombre ? element.nombre : "No tiene",
                area_negocio: element.area_negocio ?? null
              });
            })
          : "";
        this.roles.sort((a, b) => {
          const nombreA = a.nombre.toUpperCase();
          const nombreB = b.nombre.toUpperCase();
          if (nombreA < nombreB) {
            return -1;
          }
          if (nombreA > nombreB) {
            return 1;
          }
          return 0;
        });
        this.rol = JSON.parse(localStorage.getItem("roles"))[0].rol;
      });

      this.listings.status = [
        { id: 0, name: "Nuevo" },
        { id: 1, name: "Activo" },
        { id: 2, name: "Inactivo" },
        { id: -1, name: "Baja" },
      ];
      this.listings.landings = [
        { id: 1, name: "AIG" },
        { id: 2, name: "ANA" },
        { id: 3, name: "AXA" },
        { id: 4, name: "COMPARADOR" },
        { id: 5, name: "COMPARADOR-ADT" },
        { id: 6, name: "COMPARADOR-AUTOCOSMOS" },
        { id: 7, name: "GNP" },
        { id: 8, name: "HDI" },
        { id: 9, name: "PROVEEDORES" },
        { id: 10, name: "QUÁLITAS" },
        { id: 11, name: "SGMM SEGURIFY" },
        { id: 12, name: "SURA" },
        { id: 13, name: "SURA MOVILIDAD" },
        { id: 14, name: "SURA SGMM" },
        { id: 15, name: "AUTOS RASTREATOR AIG" },
        { id: 16, name: "TUSEGURO.COM" },
        { id: 17, name: "CORU.COM" },
      ];
      (this.listings.fuenteIngresoItem = [
        "Referido",
        "LinkedIn",
        "Facebook",
        "RH",
        "IECA",
        "Base de Datos",
        "Simulador",
        "Eventos",
      ]),
        (this.listings.asociadoPromotoriaItems = [
          { id: 1, name: "Afirme" },
          { id: 2, name: "ANA Seguros" },
          { id: 3, name: "GNP" },
          { id: 4, name: "Primero Seguros" },
          { id: 5, name: "Quálitas" },
          { id: 6, name: "Multiva" },
          { id: 7, name: "RSA Seguros" },
          { id: 8, name: "AIG Seguros México" },
          { id: 9, name: "HDI Seguros" },
          { id: 10, name: "Zurich" },
          { id: 11, name: "AXA" },
          { id: 12, name: "BANORTE" },
          { id: 13, name: "ATLAS" },
          { id: 14, name: "CHUBB" },
          { id: 15, name: "GMX" },
          { id: 16, name: "ALLIANZ" },
          { id: 17, name: "ARGOS" },
          { id: 18, name: "ASERTA" },
          { id: 19, name: "BX+" },
          { id: 20, name: "MUTUUS" },
          { id: 21, name: "PLAN SEGURO" },
          { id: 22, name: "PREVEM" },
          { id: 23, name: "SISNOVA" },
          { id: 24, name: "BUPA" },
        ]);
      this.listings.regimenItems = ["Física", "Moral"];
      this.listings.fuenteIngresoItems = [
        "Referido",
        "LinkedIn",
        "Facebook",
        "RH",
        "IECA",
        "Base de Datos",
        "Simulador",
        "Eventos",
      ];
      this.listings.tipoAgenteItems = [
        "Agente",
        "Comisionista",
        "Agente Apoderado",
        "Agente Promotoria",
      ];
      this.listings.tipoCedulaItems = ["A", "A1", "B", "B1", "C", "F"];
      this.listings.nivelAgenteItems = [
        "Experiencia en ventas",
        "Conocimiento en seguros",
        "Experiencia previa en seguros",
        "Actualmente trabaja en el ramo",
        "Cédula y cartera de clientes"
      ];
      this.listings.motivoBajaItems = [
        "Encontró trabajo",
        "Falta de pago de comisiones",
        "Falta de producción",
        "Falta de seguimiento del área comercial",
        "Falta de seguimiento del área de operaciones",
        "Inconformidad con el proceso de capacitación",
        "Inconformidad con la oferta de valor",
        "Exceso de faltas",
        "Ingreso a escuela",
        "Temas de salud",
        "Otro",
      ];
    },
  },
  mounted() {
    if (this.routesConfig.completeData) {
      this.listas();
    }
  },
};
</script>
