<template>
  <div>
    <v-card
      class="mx-auto"
      color="#FFFFFF"
      width="100%"
      style="border-radius: 20px; box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1)!important;"
      elevation="0"
    >
      <v-card-title style="justify-content: center;">
        <span class="text-h5 font-weight-bold">
          Estatus
        </span>
      </v-card-title>
      <v-card-text class="text-h5 font-weight-bold" style="text-align: center;">
        <div class="pt-5 pb-2">
          <v-btn
            v-if="(rol == 'ADMIN' || rol == 'ADMINAGENT' || rol == 'RECLUTA') && transforToAgent && personalData.status == 1"
            rounded
            dark
            style="background-color: #00a7e4;"
            outlined
            @click="OpenCreateAgentModal = true"
          >
            <span>
              Convertir a agente
            </span>
          </v-btn>
          <v-btn v-else rounded dark style="background-color: #f1c21b;">
            <span>
              {{ getStatus(personalData.status) }}
            </span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <create-agent-modal
      :personalData="personalData"
      :transforToAgent="transforToAgent"
      :OpenCreateAgentModal="OpenCreateAgentModal"
      @closeModal="OpenCreateAgentModal = false"
    />
  </div>
</template>

<script>
import CreateAgentModal from "@/components/commonComponents/dialogs/CreateAgentModal.vue";

export default {
  components: {
    CreateAgentModal,
  },
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      OpenCreateAgentModal: false,
    };
  },
  props: {
    personalData: Object,
    transforToAgent: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getStatus(status) {
      if (typeof this.$route.params.prospecto_id != "undefined") {
        switch (status) {
          case "1":
            return "Nuevo";
          case "2":
            return "Prospección";
          case "3":
            return "Convertido";
          case "4":
            return "Rechazado";
          case "5":
            return "Declinado";
        }
      } else return "Nuevo";
    },
  },
};
</script>
