<template>
    <div>
        <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;" >
                <v-btn
                    v-if="canEdit"
                    rounded
                    dark
                    class="common-botton"
                    @click="changeComponent"
                >
                <span style="padding-right: 11%;">
                        Editar
                </span>
                    <v-icon rigth>
                        mdi-pencil
                    </v-icon>
                </v-btn>
             </v-col>
        </v-row>
        <v-row>
            <v-col class="" cols="6" sm="6" md="6" lg="6" xl="6" >
                <span class="text-header-data ">
                    Teléfono
                </span>
            </v-col>
            <v-col class="" cols="6" sm="6" md="6" lg="6" xl="6" >
                <span class="text-header-data">
                    Celular
                </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="whitout-padding-top" cols="6" sm="6" md="6" lg="6" xl="6" >
                <span>
                    {{ contactData.telefono }}
                </span>
            </v-col>
            <v-col class="whitout-padding-top" cols="6" sm="6" md="6" lg="6" xl="6" >
                <span>
                    {{ contactData.celular }}
                </span>
            </v-col>
        </v-row>
        <v-row v-for="(correo, idx) in correosDataFormat.correos" :key='idx' style="padding-bottom: 20px;">
            <v-col cols="2" m="2" md="2" lg="1" xl="1"  :class="correo.favorito ? 'whitout-padding-bottom favorite-color' : 'whitout-padding-bottom'">
                <span>
                    <h3>{{ idx + 1 }}°</h3> 
                </span>
            </v-col>
            <v-col cols="5" m="5" md="5" lg="4" xl="4"  :class="correo.favorito ? 'whitout-padding-top favorite-color' : 'whitout-padding-top'">
                <v-row>
                    <v-col cols="12" class="whitout-padding-bottom">
                        <span class="text-header-data">
                            Correo electrónico
                        </span>
                    </v-col>
                    <v-col cols="12"  class="whitout-padding-top">
                        <span>
                            {{ correo.correo }}
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="5" m="5" md="5" lg="2" xl="2" :class="correo.favorito ? 'whitout-padding-top whitout-padding-bottom favorite-color' : 'whitout-padding-top whitout-padding-bottom'">
                <v-btn icon>
                    <v-icon :color="correo.favorito ? '#00a7e4': '#ABB1B3'">
                        {{ correo.favorito ? 'mdi-heart': 'mdi-heart-outline' }}    
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
  components: {
  },
  props:{
    contactData: Object,
    correosDataFormat: Object,
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
        correos:[
            {
                correo:'Correo1@gmail.com',
                favorito:false
            },
            {
                correo:'Correo2@gmail.com',
                favorito:true
            },
            {
                correo:'Correo3@gmail.com',
                favorito:false
            }
        ]
    };
  },

  methods: {
    changeComponent(){
        this.$emit('changeComponent', 2);
    }
  },
};
</script>

<style scoped>


.whitout-padding-bottom{
    padding-bottom: 0px !important;
}

.whitout-padding-top{
    padding-top: 0px !important;
}

.favorite-color{
    background-color: aliceblue;
}

</style>