<template>
    <div>
        <CommonExpansionPanel>
            <template v-slot:title-panel>
                Contraseña
            </template>
                <v-expansion-panel-content>
                    <view-data  @changeComponent="changeComponent"
                                :personalData="personalData" 
                                :canEdit="canEdit"
                                v-if="switch_data == 1">
                    </view-data>
                    <edit-data @changeComponent="changeComponent" 
                               :personalData="personalData"
                               :routesConfigPassword="routesConfigPassword"
                               v-else>
                    </edit-data>
                </v-expansion-panel-content>
        </CommonExpansionPanel>
    </div>
</template>

<script>

import CommonExpansionPanel from '@/components/commonComponents/CommonExpansionPanel.vue';
import ViewData from './ViewData.vue';
import EditData from './EditData.vue';

export default {
  components: {
    CommonExpansionPanel,
    ViewData,
    EditData
  },
  props:{
    personalData: Object,
    routesConfigPassword: Object,
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
        switch_data: 1,
    };
  },

  methods: {
    changeComponent(component){
        this.switch_data = component;
    }
  }
};
</script>
