<template>
    <div>
        <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;" >
                <v-btn
                    rounded
                    dark
                    outlined
                    color="#00a7e4"
                    @click="changeComponent"
                    style="margin-right: 11px;"
                >
                <span style="padding-right: 11%;">
                    Cancelar
                </span>
                </v-btn>
                <v-btn
                    rounded
                    dark
                    class="common-botton"
                    @click="formatInfoComplete"
                >
                <span style="padding-right: 11%;">
                        Guardar
                </span>
                    <v-icon rigth>
                        mdi-content-save
                    </v-icon>
                </v-btn>
             </v-col>
        </v-row>
        <commond-form 
            :request="data"
            :route="routesConfig.route"
            :run="run"
            :action="routesConfig.action"
            @cancel="run = false"
            @success="changeComponent"
            @afterError="changeComponent"
        >
            <template v-slot:data>
                <v-row>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" >
                        <v-text-field
                            label="Nombre(s)"
                            outlined
                            v-model="data.nombre"
                            primary
                            color="#00a7e4"
                            required
                            :rules="requiredRule"
                        ></v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" >
                        <v-text-field
                            label="Apellido paterno"
                            outlined
                            v-model="data.apellidoPaterno"
                            color="#00a7e4"
                            required
                            :rules="requiredRule"

                        ></v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" >
                        <v-text-field
                            label="Apellido materno"
                            outlined
                            v-model="data.apellidoMaterno"
                            color="#00a7e4"
                            required
                            :rules="requiredRule"
                        ></v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" >
                        <span class="text-header-data whitout-padding-bottom ">
                            Género
                        </span>
                        <v-radio-group
                            v-model="data.sexo"
                            row
                            :rules="requiredRule"
                        >
                            <v-radio
                                label="Femenino"
                                :value="2"
                                color="#00a7e4"
                            ></v-radio>
                            <v-radio
                                label="Masculino"
                                :value="1"
                                color="#00a7e4"
                            ></v-radio>

                        </v-radio-group>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" >
                            <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                            outlined
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="formatFechaNacimiento"
                                    label="Fecha de nacimiento"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    color="#00a7e4"
                                    required
                                    :rules="requiredRule"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="data.fechaNacimiento"
                                no-title
                                @input="menu2 = false"
                                required
                            ></v-date-picker>
                            </v-menu>
                        </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" >
                        <v-autocomplete 
                            label="Ciudad"
                            :items="stateList" 
                            item-text="dEstado" 
                            item-value="cEstado"
                            v-model="data.nacimientoEstadoId" 
                            outlined 
                            :rules="requiredRule"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" >
                        <v-text-field
                            label="RFC"
                            outlined
                            v-model="data.rfc"
                            color="#00a7e4"
                            required
                            :rules="rfcRules"
                            @input="onInputToUpper"
                        ></v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="routesConfig.completeData">
                        <v-autocomplete 
                            label="Rol"
                            :items="listings.roles" 
                            item-text="nombre" 
                            item-value="id"
                            v-model="data.rol" 
                            outlined 
                            :rules="requiredRule"
                        ></v-autocomplete>
                    </v-col>    
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="routesConfig.completeData">
                        <v-text-field 
                            label="Área de negocio"
                            v-model="areaNegocioText" 
                            outlined
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="routesConfig.completeData">
                        <v-autocomplete 
                            label="Estatus"
                            :items="listings.status" 
                            item-text="name" 
                            item-value="id"
                            v-model="data.status" 
                            outlined 
                            color="#00a7e4"
                            :rules="requiredRule"
                        ></v-autocomplete>
                    </v-col>  
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="data.rol != 'AGENT'" >
                        <v-text-field
                            label="Número de colaborador"
                            outlined
                            v-model="data.numero_colaborador"
                            primary
                            color="#00a7e4"
                            required
                            :rules="requiredRule"
                        ></v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="routesConfig.completeData && data.status == -1">
                        <v-autocomplete 
                            label="Motivo de baja"
                            :items="listings.motivoBajaItems" 
                            v-model="data.motivoBaja" 
                            outlined 
                            color="#00a7e4"
                            :rules="requiredRule"
                        ></v-autocomplete>
                    </v-col>  
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="routesConfig.completeData">
                        <v-autocomplete 
                            v-model="data.ramos"
                            :items="listings.ramoList"
                            item-text="ramo"
                            item-value="id"
                            label="Ramo"
                            multiple
                            outlined
                            color="#00a7e4"
                            >
                            <template v-slot:selection="{ item, index }">
                                <v-chip color="primary" v-if="index === 0">
                                <span>{{ item.etiqueta }}</span>
                                </v-chip>
                                <span v-if="index === 1" class="grey--text text-caption">
                                (+{{ data.ramos.length - 1 }} más)
                                </span>
                            </template>
                        </v-autocomplete>
                    </v-col>  
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="routesConfig.completeData">
                        <v-autocomplete
                            v-model="data.canales"
                            :items="listings.canalList"
                            label="Canal"
                            item-text="etiqueta"
                            item-value="id"
                            multiple
                            outlined
                            color="#00a7e4"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip color="primary" v-if="index === 0">
                                        <span>{{ item.etiqueta }}</span>
                                    </v-chip>
                                    <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ data.canales.length - 1 }} más)
                                    </span>
                                </template>
                            </v-autocomplete>
                    </v-col>  
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" 
                        v-if="routesConfig.completeData &&                     
                            (data.rol == 'ADMINCC'  ||
                            data.rol == 'MANAGERCC' ||
                            data.rol == 'AGENTCC') " >
                        <v-autocomplete
                            v-model="data.landings"
                            :items="listings.landings"
                            label="Productos"
                            item-value="id"
                            item-text="landing"
                            multiple
                            outlined
                            color="#00a7e4"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip color="primary" v-if="index === 0">
                                        <span>{{ item.landing }}</span>
                                    </v-chip>
                                    <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ data.landings.length - 1 }} más)
                                    </span>
                                </template>
                            </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row v-if="data.rol == 'AGENT'">
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4">
                        <v-autocomplete 
                            label="Fuente de Ingreso"
                            :items="listings.fuenteIngresoItems" 
                            item-text="name" 
                            item-value="id"
                            v-model="data.fuenteIngreso" 
                            outlined 
                            color="#00a7e4"
                            :rules="requiredRule"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4">
                        <v-autocomplete 
                            label="Regimen"
                            :items="listings.regimenItems"
                            v-model="data.regimen" 
                            outlined 
                            color="#00a7e4"
                            :rules="requiredRule"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4">
                        <v-autocomplete
                            label="Agente asociado a una promotoria"
                            v-model="data.agenteAsociadoPromotoria"
                            :items="listings.asociadoPromotoriaItems"
                            item-text="name"
                            item-value="id"
                            multiple
                            outlined
                            color="#00a7e4"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip color="primary" v-if="index === 0">
                                    <span>{{ item.name }}</span>
                                    </v-chip>
                                    <span v-if="index === 1" class="grey--text text-caption">
                                    (+{{ data.agenteAsociadoPromotoria.length - 1 }} más)
                                    </span>
                                </template>
                            </v-autocomplete>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4">
                        <v-autocomplete
                            label="Tipo de Agente"
                            v-model="data.tipoAgente"
                            :items="listings.tipoAgenteItems"
                            outlined
                            color="#00a7e4"
                            >
                        </v-autocomplete>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4">
                        <v-text-field
                            label="Razón Social"
                            outlined
                            v-model="data.razonSocial"
                            primary
                            color="#00a7e4"
                            required
                            :rules="requiredRule"
                        ></v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4">
                        <v-text-field
                            label="Clabe Bancaria para transferencia"
                            outlined
                            v-model="data.clabeBancaria"
                            primary
                            color="#00a7e4"
                            required
                            :rules="requiredRule"
                        ></v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="personalData.tipoAgente != 'Comisionista'">
                        <v-text-field
                            label="Clave del Agente"
                            outlined
                            v-model="data.claveAgente"
                            primary
                            color="#00a7e4"
                            required
                            :rules="requiredRule"
                        ></v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="personalData.tipoAgente != 'Comisionista'">
                        <v-autocomplete
                            label="Tipo de Cédula"
                            v-model="data.tipoCedula"
                            :items="listings.tipoCedulaItems"
                            outlined
                            color="#00a7e4"
                            :rules="requiredRule"
                            >
                        </v-autocomplete>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="personalData.tipoAgente != 'Comisionista'">
                        <v-autocomplete
                            label=" Nivel Agente"
                            v-model="data.nivelAgente"
                            :items="listings.nivelAgenteItems"
                            outlined
                            color="#00a7e4"
                            :rules="requiredRule"
                            >
                        </v-autocomplete>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="data.tipoAgente != 'Comisionista'">
                        <v-text-field
                            label="Número de Cartera"
                            v-model="data.numeroCartera"
                            outlined
                            type="number"
                            color="#00a7e4"
                            :rules="requiredRule"
                            >
                        </v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="data.tipoAgente != 'Comisionista'">
                        <v-text-field
                            label="Cedula"
                            v-model="data.cedula"
                            outlined
                            color="#00a7e4"
                            :rules="requiredRule"
                            >
                        </v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="data.tipoAgente != 'Comisionista'">
                        <v-text-field
                            label="RC"
                            v-model="data.rc"
                            outlined
                            color="#00a7e4"
                            :rules="requiredRule"
                            >
                        </v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="data.tipoAgente != 'Comisionista'">
                        <v-text-field
                            label="Vigencia de Cédula"
                            v-model="data.vigenciaCedula"
                            outlined
                            color="#00a7e4"
                            placeholder="Requerido(dd/mm/aaaa)"
                            v-mask="'##/##/####'"
                            :rules="requiredRule"
                            >
                        </v-text-field>
                    </v-col>
                    <v-col class="whitout-padding-bottom" cols="12" sm="6" md="4" lg="4" xl="4" v-if="data.tipoAgente != 'Comisionista'">
                        <v-text-field
                            label="Vigencia póliza RC"
                            v-model="data.vigenciaPolizaRc"
                            outlined
                            color="#00a7e4"
                            placeholder="Requerido(dd/mm/aaaa)"
                            v-mask="'##/##/####'"
                            :rules="requiredRule"
                            >
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </commond-form>

    </div>
</template>

<script>

import CommondForm from '@/components/commonComponents/CommondForm.vue';
import moment from "moment";
import RfcFacil from "rfc-facil";
import { getAreaNegocio } from "@/services/log/log.service.js";

export default {
  components: {
    CommondForm
  },
  props:{
    personalData: Object,
    stateList: Array,
    routesConfig: Object,
    listings: Object,
  },
  data() {
    return {
        menu2: false,
        route:'/v1/prospecto/perfil',
        run: false,
        requiredRule: [
         v => !!v || 'El campo es requerido',
        ],
        data:{},
        formatFechaNacimiento:'',
        rfcRules: [
        (v) => (v.length === 10 || v.length === 12 || v.length === 13) || "RFC debe tener 10, 12 o 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^[A-Za-z\d]{10,13}$/.test(v) ||
          "RFC no es válido, asegúrate de tener la longitud correcta y utiliza caracteres alfanuméricos",
      ],
        rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
        areaNegocioText:"",
        areasNegocio:[]
    };
  },
  watch:{
    'data.fechaNacimiento'(val) {
        this.formatFechaNacimiento = moment(val, "YYYY-MM-DD").format("DD/MM/YYYY")
        this.actualizarRfc();
    },
    "data.nombre"() {
      this.actualizarRfc();
    },
    "data.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "data.apellidoMaterno"() {
      this.actualizarRfc();
    },
    areasNegocio(){
     this.getAreaNegocio(this.personalData.rol)
    },
    "data.rol"(value){
        this.getAreaNegocio(value)
    },
},
  methods: {
    changeComponent(){
        window.location.reload();
        this.$emit('changeComponent', 1);
    },
    actualizarRfc() {
      if (
        !this.data.nombre ||
        !this.data.apellidoPaterno ||
        !this.data.apellidoMaterno ||
        !this.data.fechaNacimiento
      )
        return;
      const arregloNacimiento = this.formatFechaNacimiento
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.data.nombre,
        firstLastName: this.data.apellidoPaterno,
        secondLastName: this.data.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.data.rfc = rfc
    },
    formatInfoComplete(){
        if(this.rol == 'PROSPECTO' || typeof this.data.idProspecto != 'undefined')
            delete this.data.status;

        if(this.routesConfig.completeData){
            this.data.vigenciaCedula= this.data.vigenciaCedula
            ? moment(this.data.vigenciaCedula, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
                )
            : null
            this.data.vigenciaPolizaRc = this.data.vigenciaPolizaRc
            ? moment(this.data.vigenciaPolizaRc, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
                )
            : null
            this.data.fecha_ingreso = this.data.fecha_ingreso
            ? moment(this.data.fecha_ingreso, "DD/MM/YYYY").format(
                "YYYY-MM-DD HH:mm:ss"
                )
            : null
            this.data.fechaNacimiento = moment(this.data.fechaNacimiento,"YYYY-MM-DD").format("YYYY-MM-DD")
            this.run = true
        }
        else
            this.run = true
    },
    onInputToUpper() {
      this.polizaData.rfc = this.polizaData.rfc.toUpperCase();
    },
    getAreaNegocio(value){
        const area_negocio_id = this.listings.roles.find(e => e.id == value) ? this.listings.roles.find(e => e.id == value).area_negocio : null;
        if(area_negocio_id){
            const area_negocio = this.areasNegocio.find(e => e.id == area_negocio_id)
            if(area_negocio)
                this.areaNegocioText = area_negocio.areas + ' - ' + area_negocio.departamento + ' - ' + area_negocio.proceso
            else  
            this.areaNegocioText =  'El rol no tiene área de negocio asignado'
        }         
        else this.areaNegocioText =  'El rol no tiene área de negocio asignado'
    },
  },
  async mounted(){
    this.areasNegocio = await getAreaNegocio()
    this.getAreaNegocio(this.data.rol)
    this.data = Object.assign({}, this.personalData )
    if(this.rol == 'PROSPECTO')
        delete this.data.idProspecto;
    }

};
</script>

<style>
/* Establece el color del borde a rojo para el campo de texto personalizado */
.my-custom-text-field .v-input--outline {
  border-color: red;
}

/* Cambia el color del texto de la etiqueta flotante (floating label) */
.my-custom-text-field .v-label {
  color: red;
}
</style>