<template>
  <v-dialog
    v-model="OpenCreateAgentModal"
    persistent
    max-width="1000"
    :scrollable="true"
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="10">
            <v-icon left large color="#0070C0">
              mdi-account-convert
            </v-icon>
            <span>
              Crear Agente
            </span>
          </v-col>
          <v-col
            cols="2"
            style="text-align: end; cursor: pointer;"
            @click="closeModal"
          >
            <v-icon left color="#858C94">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <create-agent
          :personalData="personalData"
          :transforToAgent="transforToAgent">
        </create-agent>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CreateAgent from "@/components/commonComponents/forms/CreateAgent.vue";

export default {
  components: {
    CreateAgent,
  },
  props: {
    OpenCreateAgentModal: {
      type: Boolean,
      default: false,
    },
    personalData: Object,
    transforToAgent: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {

    };
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    openCreateModal() {
      this.$emit("openCreateModal");
    },
  },
};
</script>


