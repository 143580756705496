<template>
  <div class="rounded-card">
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="4" xl="4">
        <common-card-docs
          :docs="infoCards.docs"
          :routesConfigDocsCard="routesConfigNoteCard"
          :openAll="openAll"
          :canEdit="canEdit"
          @getData="getData"
          @showAll="showAll"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4" xl="4">
        <common-card-notes
          :notas="infoCards.notas"
          :routesConfigNoteCard="routesConfigNoteCard"
          :openAll="openAll"
          :canEdit="canEdit"
          @getData="getData"
          @showAll="showAll"
        />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" xl="4">
        <common-card-email 
        :emails="infoCards.emails" 
        :emailRoutesConfig="emailRoutesConfig"
        :openAll="openAll"
        :canEdit="canEdit"
        @getData="getData"
        @showAll="showAll"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonCardDocs from "@/components/commonComponents/cards/CommonCardDocs.vue";
import CommonCardEmail from "@/components/commonComponents/cards/CommonCardEmail.vue";
import CommonCardNotes from "@/components/commonComponents/cards/CommonCardNotes.vue";

export default {
  components: {
    CommonCardDocs,
    CommonCardEmail,
    CommonCardNotes,
  },
  props: {
    infoCards: Object,
    routesConfigNoteCard: Object,
    personalData: Object,
    emailRoutesConfig: Object,
    transforToAgent: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      switch_data: 1,
      openAll: false
    };
  },

  methods: {
    changeComponent(component) {
      this.switch_data = component;
    },
    getData() {
      this.$emit("getData");
    },
    showAll(val){
      this.openAll = val
    }
  },
};
</script>

<style>
.rounded-card {
  border-radius: 20px;
  max-width: "100%";
  background-color: #f6f5f9;
}
</style>
