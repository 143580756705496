<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="changeComponent"
          style="margin-right: 11px;"
        >
          <span style="padding-right: 11%;">
            Cancelar
          </span>
        </v-btn>
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="eliminatedEmptyEmails"
        >
          <span style="padding-right: 11%;">
            Guardar
          </span>
          <v-icon rigth>
            mdi-content-save
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <commond-form
      :request="data"
      :route="routesContactData.routeCommond"
      :run="run"
      :action="routesContactData.routeCommondAction"
      @cancel="run = false"
      @success="ChangeEmails"
      @afterError="changeComponent"
    >
      <template v-slot:data>
        <v-row>
          <v-col cols="12" m="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Teléfono"
              outlined
              v-model="data.telefono"
              primary
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
              placeholder="##########"
              v-mask="'##########'"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Celular"
              outlined
              v-model="data.celular"
              primary
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
              placeholder="##########"
              v-mask="'##########'"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-for="(correo, idx) in dataCorreos.correos" :key="idx">
          <v-col
            cols="2"
            m="2"
            md="2"
            lg="1"
            xl="1"
            class="whitout-padding-bottom"
          >
            <span>
              <h3>{{ idx + 1 }}°</h3>
            </span>
          </v-col>
          <v-col
            cols="8"
            m="5"
            md="5"
            lg="4"
            xl="4"
            class="whitout-padding-top"
          >
            <CommonEmailAutocomplete
              :emailEdit="correo.correo"
              @emailUpdate="(e) => (correo.correo = e)"
            ></CommonEmailAutocomplete>
          </v-col>
          <v-col
            cols="2"
            m="5"
            md="5"
            lg="2"
            xl="2"
            class="whitout-padding-top whitout-padding-bottom"
          >
            <v-btn
              @click="chekDeleteIdx(idx)"
              icon
              v-if="dataCorreos.correos.length > 1"
            >
              <v-icon color="#ABB1B3">
                mdi-delete
              </v-icon>
            </v-btn>
            <v-btn @click="changeFavoriteEmail(idx)" icon>
              <v-icon :color="correo.favorito ? '#00a7e4' : '#ABB1B3'">
                {{ correo.favorito ? "mdi-heart" : "mdi-heart-outline" }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          class="whitout-padding-top"
          v-if="dataCorreos.correos.length < 5"
        >
          <v-col
            cols="12"
            m="12"
            md="6"
            lg="6"
            xl="6"
            class="whitout-padding-top"
          >
            <v-btn elevation="0" block @click="AddNewMail">
              <v-icon rigth>
                mdi-plus-circle-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
    <CommonConfirmationModal
      :dialog="dialog"
      @userChoice="deleteIndexChoice"
    ></CommonConfirmationModal>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import CommonConfirmationModal from "@/components/commonComponents/CommonConfirmationModal.vue";
import { mainAxios } from "@/mainAxios.js";
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";

export default {
  components: {
    CommondForm,
    CommonConfirmationModal,
    CommonEmailAutocomplete,
  },
  data() {
    return {
      contactData: {
        correos: [],
      },
      route: "/v1/prospecto/perfil",
      run: false,
      dialog: false,
      idxToDelete: null,
      requiredRule: [(v) => !!v || "El campo es requerido"],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      dataCorreos: [],
      data: {},
      ids: [],
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
    };
  },
  props: {
    telefonos: Object,
    correosDataFormat: Object,
    routesContactData: Object,
  },
  methods: {
    changeComponent() {
      this.$emit("changeComponent", 1);
    },
    changeFavoriteEmail(idx) {
      if (this.dataCorreos.correos[idx].correo != "") {
        var idxActualFavorite = this.dataCorreos.correos.findIndex(
          (i) => i.favorito == true
        );
        if (idxActualFavorite != idx) {
          this.dataCorreos.correos[idxActualFavorite].favorito = false;
          this.dataCorreos.correos[idx].favorito = true;
        }
      } else {
        this.$toast.info("¡Primero completa el campo correo!", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    chekDeleteIdx(idx) {
      this.idxToDelete = idx;
      if (this.dataCorreos.correos[idx].correo != "") this.dialog = true;
      else this.deleteIndexChoice(true);
    },
    deleteIndexChoice(val) {
      if (val) {
        var idxActualFavorite = this.dataCorreos.correos.findIndex(
          (i) => i.favorito == true
        );
        if (typeof this.dataCorreos.correos[this.idxToDelete].id != "undefined")
          this.ids.push(this.dataCorreos.correos[this.idxToDelete].id);
        this.dataCorreos.correos.splice(this.idxToDelete, 1);
        if (idxActualFavorite == this.idxToDelete) {
          this.dataCorreos.correos[0].favorito = true;
        }
      }
      this.idxToDelete = null;
      this.dialog = false;
    },
    AddNewMail() {
      this.dataCorreos.correos.push({
        correo: "",
        favorito: false,
      });
    },
    eliminatedEmptyEmails() {
      const emptyEmailIndices = this.dataCorreos.correos.reduce(
        (indices, correo, index) => {
          if (correo.correo === "") {
            indices.push(index);
          }
          return indices;
        },
        []
      );
      if (emptyEmailIndices.length > 0) {
        emptyEmailIndices.reverse();
        emptyEmailIndices.forEach((index) => {
          this.dataCorreos.correos.splice(index, 1);
        });
      }
      this.run = true;
    },
    ChangeEmails() {
      if (this.data.idProspecto) {
        if (this.ids.length > 0) {
          this.deleteEmails();
        }
        this.addEmail();
        this.editEmail();
      }
      if (this.telefonos.idAgente) {
        this.actualizarCorreosAgente();
        if (this.ids.length > 0) {
          this.deleteCorreosAgente();
        }
      }

      this.$emit("changeComponent", 1);
    },
    deleteEmails() {
      var body = {
        ids: this.ids,
        idProspecto: this.data.idProspecto,
      };
      mainAxios
        .put(this.routesContactData.deleteEmails, body, this.config)
        .then((value) => {
          console.log("Si se borraron, ", value);
        });
    },
    addEmail() {
      var newEmail = this.dataCorreos.correos.filter(
        (email) => typeof email.id == "undefined"
      );
      if (newEmail.length > 0)
        newEmail.map((email) => {
          var body = {
            ...(this.data.idProspecto && {
              idProspecto: this.data.idProspecto,
            }),
            ...(this.idAgente && { agente_id: this.idAgente }),
            ...email,
          };
          mainAxios
            .post(this.routesContactData.insertEmails, body, this.config)
            .then((resp) => {
              console.log(resp.data);
            })
            .catch((error) => {
              this.$toast.info(error.response.data.message, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            });
        });
    },
    editEmail() {
      var newEmail = this.dataCorreos.correos.filter(
        (email) => typeof email.id != "undefined"
      );
      if (newEmail.length > 0)
        newEmail.map((email) => {
          var body = {
            idProspecto: this.data.idProspecto ? this.data.idProspecto : null,
            ...email,
          };
          mainAxios.put(
            this.routesContactData.changeEmails + email.id,
            body,
            this.config
          );
        });
    },
    actualizarCorreosAgente() {
      console.log("aqui estan los correos:", this.dataCorreos.correos);
      for (let correo of this.dataCorreos.correos) {
        if (correo.id) {
          mainAxios.post(
            `${this.routesContactData.changeEmails}${correo.id}`,
            {
              correo: correo.correo,
              favorito: correo.favorito,
            },
            this.config
          );
        } else {
          mainAxios.post(
            this.routesContactData.insertEmails,
            {
              correo: correo.correo,
              favorito: correo.favorito,
              ...(this.telefonos.idAgente && {
                agente_id: this.telefonos.idAgente,
              }),
              ...(this.telefonos.idCliente && {
                cliente_id: this.telefonos.idCliente,
              }),
            },
            this.config
          );
        }
      }
    },
    deleteCorreosAgente() {
      if (!this.ids.length) return;
      mainAxios.post(
        this.routesContactData.deleteEmails,
        {
          id: this.ids,
        },
        this.config
      );
    },
  },
  mounted() {
    this.data = {
      telefono: this.telefonos.telefono,
      celular: this.telefonos.celular,
      idProspecto: this.telefonos.idProspecto,
    };
    this.dataCorreos = Object.assign(this.correosDataFormat);
    // if(this.rol == 'PROSPECTO')
    //     delete this.data.idProspecto;
  },
};
</script>

<style scoped>
.text-header-data {
  color: #0070c0;
}

.whitout-padding-bottom {
  padding-bottom: 0px !important;
}

.whitout-padding-top {
  padding-top: 0px !important;
}
</style>
